// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cccccc !important;
  position: absolute;
}

* {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Global CSS*/
@media (min-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 95% !important;
  }

  .header-bloc,
  .subheader-bloc {
    margin: auto;
    z-index: 900;
  }
  .subheader-bloc {
    z-index: 800;
    top: 77px;
  }
}

@media (min-width: 1700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1690px !important;
  }

  .header-bloc,
  .subheader-bloc {
    max-width: 1690px !important;
    z-index: 900;
    margin: auto;
  }
  .subheader-bloc {
    z-index: 800;
    top: 77px;
  }
}

@media (min-width: 100px) {
  .header-bloc,
  .subheader-bloc {
    max-width: 1620px !important;
    z-index: 800;
    margin: auto;
  }
  .subheader-bloc {
    z-index: 800;
    top: 77px;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1620px !important;
  }
}

@media (min-width: 2px) {
  .d-sm-flex {
    display: flex !important;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 1030px !important;
  }

  .header-bloc,
  .subheader-bloc {
    min-width: 1030px !important;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accentLink {
  color: #fb0e8b !important;
}
.bg-accent {
  background-color: #fb0e8b !important;
  border-color: #fb0e8b !important;
}

.gray {
  filter: grayscale(100%);
  color: #555;
  opacity: 0.7;
}

.breadcrumb {
  margin-bottom: 0 !important;
  background-color: white !important;
  border-radius: 0 !important;
  justify-content: flex-end;
  padding-right: 3rem !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-size: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
  color: #555 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000 !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  border-color: white !important;
  border-bottom: 3px solid black !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: white !important;
  border-bottom: 3px solid #aaa !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fb0e8b;
  color: white;
}

.logoPart {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0 !important;
}

/* .navbar{
  padding: .5rem 3rem !important;
} */
.rdt_TableCol_Sortable * {
  font-weight: bold !important;
  font-size: 18px !important;
}

.ant-layout-sider-zero-width-trigger {
  top: 0 !important;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: visible !important;
  line-height: 40px;
}
.ant-layout-sider ul {
  background-color: #eee !important;
  padding-top: 20px;
  padding-right: 24px;
  box-sizing: border-box;
}
.ant-layout-sider {
  background: #eee;
}
.ant-menu-submenu-arrow {
  display: none !important;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 20px;
  line-height: 20px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 20px;
  line-height: 20px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-layout-sider-children {
  background-color: #eee;
}
// .sidemenu{
//   max-width: 200px;
//   overflow: auto;
// }
::-webkit-scrollbar {
  height: 0.4em;
  /* border-radius: 150vw; */
  background: transparent;
  width: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.searchBar ::-webkit-scrollbar {
  height: 0.14em;
}
/* 
::-webkit-scrollbar:hover {
  width: .5rem;

} */

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  padding: 0.1rem;
  background: #fb0e8b55;
  margin: 1vw;
  box-shadow: 0px 0px 2px transparent inset;
  /* border-radius: 150vw; */
}

/*ascenseur*/
::-webkit-scrollbar-thumb:hover {
  box-shadow: none;
  /* border: 2px solid #256434; */
  cursor: pointer;
  background: #fb0e8b;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fb0e8b;
}
.ant-slider-track {
  background-color: #f547a4 !important;
}
.ant-slider-handle {
  border: 2px solid #f547a4 !important;
  background-color: #f547a4 !important;
}
.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px #f547a42f !important;
}

.camelCase::first-letter {
  text-transform: uppercase;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}
.boxicon * {
  display: flex !important;
}
.ant-menu ul {
  padding-left: 25px !important;
  padding-top: 1px !important;
  min-width: 500px;
}
.MuiTreeItem-label {
  font-family: "Open Sans" !important;
  font-weight: 500 !important;
  color: #555;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content {
  width: 100%;
  fill: #f547a4;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: #f547a420 !important;
  border-radius: 0 2rem 2rem 0;
  // background-color: transparent!important;
  color: #fb0e8b !important;
}
.ant-layout-sider-children ul {
  padding-top: 1px !important;
  padding-right: 0 !important;
}

.disable-mouse-selection {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.rdt_TableCol_Sortable * {
  font-weight: bold !important;
  font-size: 18px !important;
  border: none;
}
.rdt_Table div[role="row"] {
  border: none;
  padding: 0.5rem 0;
}

.ant-layout-sider {
  background-color: #eee !important;
}

/**
SMALL SCREENS
*/
@media (min-width: 2) {
  .header-bloc,
  .subheader-bloc {
    min-width: 960px;
  }
}
@media (min-width: 2px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
}
@media (max-width: 2px) {
  .container {
    margin: 55px auto;
  }
}

@media (min-width: 2px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    min-width: 960px;
  }
}

@media (min-width: 2px) {
  .header-bloc,
  .subheader-bloc {
    min-width: 960px;
  }
}
@media (min-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 960px;
  }

  .header-bloc,
  .subheader-bloc {
    min-width: 960px;
  }
}

@media (min-width: 1700px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    min-width: 960px;
    max-width: 1690px;
  }

  .header-bloc,
  .subheader-bloc {
    min-width: 960px;
    max-width: 1690px;
  }
}

.MuiTreeItem-label {
  width: 100%;
  position: relative;
  padding-left: 4px;
  // background-color: green !important;
  // width: 25px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
