// $primary: pink;
// $success: blue;
// $danger: red;
// @import '~bootstrap/scss/bootstrap';


/* import only the Bootstrap files needed for customizations */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/* make the customizations */
$theme-colors: (
  "primary": #fb0e8b,
  "danger": #ff4136
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
